import React from 'react'
import ConnectedPlatforms from './ConnectedPlatformModal'

const Settings: React.FC = () => {
  return (
    <div className='row'>
      {/* Your ConnectedPlatforms component here */}
      <div className='col-12'>
        <div className='card shadow my-2 p-4'>
          <h4 className='text-primary fs-4 mb-4'>Connected Platforms</h4>
          <ConnectedPlatforms />
        </div>
      </div>
    </div>
  )
}

export default Settings
