import clsx from 'clsx'
import React, { useState } from 'react'
import {Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions'
import { setPlatformChoiceAsync } from 'src/redux/actions/authActions'
import { fetchConnectedPlatformAsync } from 'src/redux/actions/platformActions'
import { setStepsData } from 'src/redux/slices/tourPopupSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'

const ConfirmPlatformConnectionType = () => {

  const [isPrimary, setIsPrimary] = useState<'Primary'|'Secondary'|string>('Primary')
  const dispatch = useAppDispatch()
  const [isCheckedTerm, setIsCheckedTerm] = useState(false)
  const [friendlyName, setFriendlyName] = useState('')
  const [loading, setLoading] = useState(false)
  const { successToast,warningToast } = useAppToast()
  const navigate = useNavigate()
  const { stepsData } = useAppSelector(state=>state.tourPopup)
  const {fetchedConnectedPlatformData, fetchedConnectedPlatformLoading, mainStoreData} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const { fetchedUserAccountData } = useAppSelector(state=>state.userAccountData)

  const handleChangePlatformConnectionType = async () => {
      if(fetchedUserAccountData){
        if(!friendlyName){
          warningToast('Channel name is required field')
          return
        }

        const payload = {
          isMainStore: isPrimary=='Primary' ? true : false ,
          user_id:fetchedUserAccountData?.id!,
          platform_id:mainStoreData?.id,
          friendlyName:friendlyName
        }
        setLoading(true)
        dispatch(setPlatformChoiceAsync(payload))
        .then((res)=>{
          console.log('response of platform choice', res.payload);
          if(res.payload?.status_code==200){
            successToast('Channel connected successfully')
            dispatch(fetchUserAccountDetailsAsync())
            dispatch(fetchConnectedPlatformAsync()).then(()=>{
              dispatch(setStepsData({stepData:{stepOne:'finished',stepTwo:'active', stepThree:'inactive'}, visible:true}))
            })
          }
          setLoading(false)
        }).
        catch((err)=>{
          setLoading(false)
          warningToast('Connection type not changed'+ err+'' )
        })
      }
  }

  return (
    <div className='d-flex flex-column'>
      {
        fetchedConnectedPlatformData?.length ? 
        <>
        <div className='d-flex justify-content-between align-items-center' >
          <h5>Confirm Source Channel Connection</h5>
          <button type='button' className='btn btn-sm btn-primary fs-6 fw-bold' 
          onClick={()=>{
              dispatch(setStepsData({stepData:stepsData,visible:false}))
              navigate('/pricing')
            }} > <i className={'bi bi-coin fs-4 me-2'}></i> Manage Plan</button>
        </div>
          <div className='row align-items-center justify-content-center my-5'>
            <div className='col-xl-7 col-lg-9 col-md-10 col-12'>
              <div className='card min-h-100'>
                <div className='card-body p-5'>

                  <div className='row align-items-center '>
                    <div className='col-8'>
                      <p className='fs-4 fw-semibold '>{fetchedConnectedPlatformData[0]?.display_name}</p>
                    </div>
                    <div className='col-4 text-end'>
                      <img
                        src={fetchedConnectedPlatformData[0]?.platform_info?.logo_ref}
                        className='h-50px'
                      />
                    </div>
                  </div>
                  <div className='separator my-2'></div>
                  <div className='mt-4' >
                    <label className='form-label' htmlFor="">Channel Name</label>
                    <input type="text" required value={friendlyName} onChange={(ev)=>setFriendlyName(ev.target.value)} placeholder='Add a Channel Name to remember this Store connection' className='form-control form-control-solid'  />
                  </div>
                  <div className='row mt-8 '>
                    <div className='col-sm-6 col-12'>
                      <label htmlFor='primaryConnection' className='border p-3 py-4 rounded shadow h-100 align-content-center bg-hover-light-primary w-100'>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid d-flex'>
                            <input id='primaryConnection' value={'Primary'} checked={isPrimary==='Primary'} type='radio' className='form-check-input mt-1' onChange={(ev)=>{
                              setIsPrimary(ev.target.value)
                            }} />
                            <p className={clsx(' ms-1 mb-0 d-flex align-items-center', isPrimary=='Primary' ? 'text-primary fw-bold fs-5' : 'fs-5'  )}>
                              Make Primary Channel{' '}
                              <OverlayTrigger overlay={<Tooltip>Primary Connection Detail</Tooltip>}>
                                <i className='bi bi-info-circle fs-5 ms-1'></i>
                              </OverlayTrigger>{' '}
                            </p>
                        </div>
                      </div>
                      </label>
                    </div>
                    <div className='col-sm-6 col-12 mt-sm-0 mt-4'>
                      <label htmlFor='secondaryConnection' className='border p-3 py-4 rounded shadow h-100 bg-hover-light-primary w-100'>
                        <div className='d-flex align-items-center'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid d-flex'>
                            <input id='secondaryConnection' value={'Secondary'}  checked={isPrimary==='Secondary'} type='radio' className='form-check-input mt-1' onChange={(ev)=>{
                              setIsPrimary(ev.target.value)
                            }} />
                            <p className={clsx(' ms-1 mb-0 d-flex align-items-center', isPrimary=='Secondary' ? 'text-primary fs-5 fw-bold' : 'fs-5'  )}>
                              Make Secondary Channel{' '}
                              <OverlayTrigger overlay={<Tooltip>Secondary Connection Detail</Tooltip>}>
                                <i className='bi bi-info-circle fs-5 ms-1'></i>
                              </OverlayTrigger>{' '}
                            </p>
                        </div>
                      </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className='d-flex p-5 pt-0 align-items-start'>
                  <input type='checkbox' className='form-check d-inline-block' checked={isCheckedTerm} onChange={(ev)=>setIsCheckedTerm(ev.target.checked)} />
                  <p className='fs-6 mb-0 ms-2 fw-semibold'>
                    I confirm that I have reviewed this setting and understand it cannot be changed
                    later without assistance. If you wish to change this in future please contact our support at <a href="mailto:help@mycommercium.com">help@mycommercium.com</a>
                  </p>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <button disabled={loading|| !isCheckedTerm} className='btn btn-primary btn-sm my-4 w-50' onClick={handleChangePlatformConnectionType} >{ loading ? 'Saving...' : 'Save & Proceed'}</button>
                </div>
              </div>
            </div>
          </div>
        </> : 
        <div className='p-5 mt-5' >
          <div className='card' >
            <div className="card-body">
              <h5>No Platforms found to change Connection Type</h5> 
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default ConfirmPlatformConnectionType
