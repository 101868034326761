import React, {FC, useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import {useAppDispatch} from 'src/redux/storeHooks'
import {fetchPlatformsWithQueryAsync} from 'src/redux/actions/platformActions' // Import your thunk
import MarketPlace from './MarketPlace'
import ECommercePlatforms from './ECommercePlatforms'
import CRMPlatforms from './CRMPlatforms'
import ShippingPlatforms from './ShippingPlatforms'
import AccountingPlatforms from './AccountingPlatforms'
import Settings from './Settings'

const Onboard: FC = () => {
  const dispatch = useAppDispatch() // Typed dispatch
  const [show, setShow] = useState(false)
  const [selectedStep, setSelectedStep] = useState<string>('Marketplace Platforms')
  const [platforms, setPlatforms] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const fetchPlatformsByType = async (type: string) => {
    setLoading(true)
    try {
      const response: any = await dispatch(fetchPlatformsWithQueryAsync({type: `${type}`})).unwrap() // Unwrap to get the fulfilled value
      setPlatforms(response.data || [])
    } catch (error) {
      console.error('Failed to fetch platforms:', error)
      setPlatforms([])
    } finally {
      setLoading(false)
    }
  }

  const handleStepClick = (step: string) => {
    setSelectedStep(step)
    const typeMap: Record<string, string> = {
      'Marketplace Platforms': 'MARKETPLACE',
      'E-Commerce Platforms': 'ECOMMERCE',
      'CRM Platforms': 'CRM',
      'Shipping Platforms': 'SHIPMENT',
      'Accounting Platforms': 'ACCOUNTING',
    }
    const type = typeMap[step]
    if (type) {
      fetchPlatformsByType(type) // Trigger API call based on step type
    }
  }

  useEffect(() => {
    setShow(true)
    fetchPlatformsByType('MARKETPLACE') // Initial load
  }, [])

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size='xl'
        centered
        backdrop='static'
        keyboard={false}
        animation={false}
        className='modal-static'
        style={{minHeight: '400px'}}
        dialogClassName='custom-modal-dialog'
      >
        <Modal.Header closeButton className='sticky-top bg-white z-index-modal-header'>
          <Modal.Title>Step Progress</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='d-flex overflow-auto bg-light-primary'
          style={{maxHeight: '8vh', whiteSpace: 'nowrap'}}
        >
          {[
            'Marketplace Platforms',
            'E-Commerce Platforms',
            'CRM Platforms',
            'Shipping Platforms',
            'Accounting Platforms',
            'Settings',
          ].map((step) => (
            <div
              key={step}
              className={clsx(
                'd-flex align-items-center px-4 py-2',
                selectedStep === step ? 'text-primary' : 'text-secondary',
                'cursor-pointer',
                'flex-grow-1',
                'justify-content-center'
              )}
              onClick={() => handleStepClick(step)}
              style={{minWidth: '0'}}
            >
              <small className='fs-6 fw-bold lh-1 text-center'>{step}</small>
            </div>
          ))}
        </Modal.Body>

        <Modal.Body className='overflow-auto' style={{maxHeight: '70vh'}}>
          <div className=''>
            {loading ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{minHeight: '70vh'}}
              >
                <div className='spinner-border text-primary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {selectedStep === 'Marketplace Platforms' && <MarketPlace platforms={platforms} />}
                {selectedStep === 'E-Commerce Platforms' && (
                  <ECommercePlatforms platforms={platforms} />
                )}
                {selectedStep === 'CRM Platforms' && <CRMPlatforms platforms={platforms} />}
                {selectedStep === 'Shipping Platforms' && (
                  <ShippingPlatforms platforms={platforms} />
                )}
                 <div className='allplatform row g-3 gx-2 g-xl-8 my-1'>
                {selectedStep === 'Accounting Platforms' && (
                  <AccountingPlatforms platforms={platforms} />
                )}
                </div>
                {selectedStep === 'Settings' && <Settings />}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <style>{`
        .custom-modal-dialog .modal-content {
          min-height: 650px;
        }
      `}</style>
    </>
  )
}

export default Onboard
