import React, {FC, useState} from 'react'

interface Option {
  value: string
  label: string
}

interface MappingProps {
  sourceLabel: string
  sourceOptions: Option[]
  destinationLabel: string
  destinationOptions: Option[]
}

const ImportShipmentMapping: FC<MappingProps> = ({
  sourceLabel,
  sourceOptions,
  destinationLabel,
  destinationOptions,
}) => {
  const [mappings, setMappings] = useState([{id: 0}])

  // Function to add a new mapping
  const handleAddMapping = () => {
    const newId = mappings.length
    setMappings([...mappings, {id: newId}])
  }

  // Function to undo the last mapping
  const handleUndoMapping = () => {
    if (mappings.length > 1) {
      // Prevent removing the last mapping
      setMappings(mappings.slice(0, mappings.length - 1))
    }
  }

  return (
    <div className='mb-sm-0 mb-2 p-2 mt-2'>
      {mappings.map((mapping, index) => (
        <div key={mapping.id} className='row justify-content-start mb-2'>
          {/* Source Dropdown */}
          <div className='col-12 col-sm-5'>
            <div>
              <label htmlFor={`sourceSelect-${mapping.id}`} className='form-check-label'>
                {sourceLabel}
              </label>
              <select
                className='form-select form-select-lg mt-2'
                id={`sourceSelect-${mapping.id}`}
                name={`mappings[${mapping.id}][source_platform_data_id]`}
              >
                <option value=''>Select an option</option>
                {sourceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Connecting Icon */}
          <div className='col-12 col-sm-1 d-flex justify-content-center'>
            <div className='d-flex justify-content-center align-items-center'>
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fas'
                data-icon='arrow-right-arrow-left'
                className='svg-inline--fa fa-arrow-right-arrow-left'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 448 512'
                width='10'
                height='10'
              >
                <path
                  fill='currentColor'
                  d='M438.6 150.6c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 32 96C14.3 96 0 110.3 0 128s14.3 32 32 32l306.7 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96zm-333.3 352c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 416 416 416c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96z'
                ></path>
              </svg>
            </div>
          </div>

          {/* Destination Dropdown */}
          <div className='col-12 col-sm-5'>
            <div>
              <label htmlFor={`destinationSelect-${mapping.id}`} className='form-check-label'>
                {destinationLabel}
              </label>
              <select
                className='form-select form-select-lg mt-2'
                id={`destinationSelect-${mapping.id}`}
                name={`mappings[${mapping.id}][destination_platform_data_id]`}
              >
                <option value=''>Select an option</option>
                {destinationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Undo Button (only show after the first mapping) */}
          {index > 0 && (
            <div className='col-12 col-sm-1 d-flex justify-content-end mt-4'>
              <button type='button' className='btn btn-sm' onClick={handleUndoMapping}>
                <i
                  className='bi bi-backspace-fill'
                  style={{fontSize: '20px', color: '#f10000'}}
                ></i>
              </button>
            </div>
          )}
        </div>
      ))}

      {/* Add Button */}
      <div className='d-flex justify-content-end'>
        <button className='btn btn-primary btn-sm me-3' type='button' onClick={handleAddMapping}>
          <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fas'
            data-icon='plus'
            className='svg-inline--fa fa-plus'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 448 512'
            width='16'
            height='16'
          >
            <path
              fill='currentColor'
              d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z'
            ></path>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default ImportShipmentMapping
