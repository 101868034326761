import React, {FC, useState} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {ConnectedPlatform} from 'src/interfaces/Platform.interface'
import {KTSVG} from 'src/marketConnect/helpers'
import {loggedInAdminAccessToken} from 'src/utils/constants'
import ExpandableSection from './ExpandableSection'
import Select from 'react-select'
import InfoTooltip from './InfoTooltip'
import ImportShipmentMapping from './toggle-settings/ImportShipmentToggle'

type ConnectedPlatformStripe = {
  platform: ConnectedPlatform
  onDelete: () => void
}

const ConnectPlatformBox: FC<ConnectedPlatformStripe> = ({platform, onDelete}) => {
  const [expandedSections, setExpandedSections] = useState({
    isCreateOrders: false,
    isImportOrders: false,
    isImportShipments: false,
    isCreateShipments: false,
  })

  // Function to toggle all sections at once (open or close them all)
  const toggleAllSections = () => {
    const allOpen = Object.values(expandedSections).every((value) => value) // Check if all sections are open
    setExpandedSections({
      isCreateOrders: !allOpen,
      isImportOrders: !allOpen,
      isImportShipments: !allOpen,
      isCreateShipments: !allOpen,
    })
  }

  const dummySourceOptions = [
    {value: '1', label: 'Source Option 1'},
    {value: '2', label: 'Source Option 2'},
    {value: '3', label: 'Source Option 3'},
  ]

  const dummyDestinationOptions = [
    {value: 'A', label: 'Destination Option A'},
    {value: 'B', label: 'Destination Option B'},
    {value: 'C', label: 'Destination Option C'},
  ]

  const selectOptions = [
    {label: 'Shopify', value: 'shopify'},
    {label: 'Amazon', value: 'amazon'},
    {label: 'Flipkart', value: 'flipkart'},
    {label: 'WooCommerce', value: 'woocommerce'},
  ]

  const [formValues, setFormValues] = useState({
    shipment_setting: {
      isCreateOrders: false,
      isImportOrders: false,
      isImportShipments: false,
      isCreateShipments: false,
    },
    order_setting: {
      sync_start_date: '2024-12-01T12:00',
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target
    if (type === 'checkbox') {
      setFormValues((prevValues) => ({
        ...prevValues,
        shipment_setting: {
          ...prevValues.shipment_setting,
          [name]: checked,
        },
      }))
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        order_setting: {
          ...prevValues.order_setting,
          [name]: value,
        },
      }))
    }
  }

  return (
    <div className='card shadow p-4 mb-4'>
      <div className='card-title d-flex justify-content-between w-100'>
        <h4 className='text-primary fs-4'>{platform.platform_info.type}</h4>
        <i className='bi bi-trash3-fill fs-4 cursor-pointer pe-2' onClick={onDelete}></i>
      </div>
      <div className='d-sm-flex flex-md-row justify-content-between align-items-center rounded position-relative'>
        <div className='d-flex flex-row align-items-center'>
          <img
            src={platform.platform_info.logo_ref}
            alt='Channel Logo'
            className='me-2'
            style={{width: '50px', height: '50px'}}
          />
          <h4 className='title mb-0'>{platform.display_name}</h4>
        </div>
        <span
          className='text-success fw-bold d-flex align-items-center justify-content-center align-self-center fs-5'
          style={{height: '36px', lineHeight: '36px'}}
        >
          Connected
        </span>
        <div className='d-md-inline-flex d-flex mt-sm-12 mt-4 justify-content-sm-start justify-content-between'>
          <div className='d-flex align-items-center'>
            {(['AIMS360', 'BIGCOMMERCE'].includes(platform.code) ||
              localStorage.getItem(loggedInAdminAccessToken)) && (
              <OverlayTrigger
                placement='top'
                delay={{show: 250, hide: 400}}
                overlay={(props) => (
                  <Tooltip id='button-tooltip' {...props}>
                    Platform Setup
                  </Tooltip>
                )}
              >
                <button
                  onClick={toggleAllSections} // Toggle all sections at once
                  className='cursor-pointer border-0 bg-transparent'
                >
                  <KTSVG
                    path='media/icons/duotune/coding/cod001.svg'
                    className='svg-icon-muted svg-icon-2hx'
                  />
                </button>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </div>

      {/* Expanded Sections */}
      {expandedSections.isCreateOrders && (
        <ExpandableSection
          title='Create Orders'
          stateName='isCreateOrders'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <label className='form-label'>
            Select Source Platforms
            <InfoTooltip message='Select platforms for creating orders.' />
          </label>
          <Select
            isMulti
            options={selectOptions}
            onChange={(selected) => console.log('Selected Platforms:', selected)}
          />
        </ExpandableSection>
      )}

      {expandedSections.isImportOrders && (
        <ExpandableSection
          title='Import Orders'
          stateName='isImportOrders'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <label className='form-label'>Sync Start Date</label>
          <input
            type='datetime-local'
            className='form-control'
            name='sync_start_date'
            value={formValues.order_setting.sync_start_date}
            onChange={handleChange}
          />
        </ExpandableSection>
      )}

      {expandedSections.isImportShipments && (
        <ExpandableSection
          title='Import Shipments'
          stateName='isImportShipments'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <label className='form-label'>Select Shipment Store</label>
          <Select
            isMulti
            options={selectOptions}
            onChange={(selected) => console.log('Selected Shipment Stores:', selected)}
          />
          <div className='separator mt-8'></div>
          <ImportShipmentMapping
            sourceLabel='Source Label'
            sourceOptions={dummySourceOptions}
            destinationLabel='Destination Label'
            destinationOptions={dummyDestinationOptions}
          />
        </ExpandableSection>
      )}

      {expandedSections.isCreateShipments && (
        <ExpandableSection
          title='Create Shipments'
          stateName='isCreateShipments'
          values={formValues.shipment_setting}
          handleChange={handleChange}
        >
          <label className='form-label'>Select Shipment Store</label>
          <Select
            isMulti
            options={selectOptions}
            onChange={(selected) => console.log('Selected Shipment Stores:', selected)}
          />
        </ExpandableSection>
      )}
    </div>
  )
}

export default ConnectPlatformBox
