import React, {FC, useState} from 'react'
import ConnectPlatformModal from './PlatformModal'
import {Platform} from 'src/interfaces/Platform.interface' // Import the Platform interface

// Update PlatformCardPropType to allow platformId as number
type PlatformCardPropType = {
  data: Platform
  onClickConnectTop: (params: {platformId: number | null; isConnecting: boolean}) => void // Change platformId to number
}

const Card: FC<PlatformCardPropType> = ({data, onClickConnectTop}) => {
  const [showConnectPlatformModal, setShowConnectPlatformModal] = useState<boolean>(false)

  const handleCloseModal = () => {
    setShowConnectPlatformModal(false)
    onClickConnectTop({platformId: null, isConnecting: false})
  }

  const onClickConnect = () => {
    setShowConnectPlatformModal(true)
    onClickConnectTop({platformId: data.id, isConnecting: true})
  }

  const handleDataReceived = (receivedData: any, friendlyName: string) => {
    console.log('Data received:', receivedData, 'Friendly Name:', friendlyName)
    handleCloseModal()
  }

  return (
    <div className='col-sm-6 col-lg-6 col-xl-4 col-md-6 m-0 mt-3'>
      <div className='card position-relative mb-md-3 mb-2 mb-xl-8 cursor-pointer h-60 p-3 bg-hover-light-primary cursor-pointer border-0 shadow'>
        <div className='card-body align-items-center p-3 px-7'>
          {/* Platform Information */}
          <div className='d-flex row'>
            <div className='col-xxl-9 col-md-8 col-9'>
              <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
                <div className='fw-bolder fs-4 fs-md-2 mb-2'>{data.display_name}</div>
              </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-3 d-flex justify-content-end'>
              <div className='w-100px h-50px'>
                <img
                  src={data.logo_ref}
                  alt={data.display_name}
                  style={{width: '100%', height: '100%', objectFit: 'contain'}}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Connect Button */}
        {!showConnectPlatformModal && (
          <div className='d-flex justify-content-end my-2'>
            <button className='btn btn-primary btn-sm me-2' onClick={onClickConnect}>
              Connect
            </button>
          </div>
        )}
        {/* Connect Modal */}
        {showConnectPlatformModal && (
          <ConnectPlatformModal
            isVisible={showConnectPlatformModal}
            closeModal={handleCloseModal}
            data={data}
            onDataReceived={handleDataReceived}
          />
        )}
      </div>
    </div>
  )
}

export default Card
