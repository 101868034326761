import React, {FC, useEffect, useState} from 'react'
import OrderReportChart from './OrderReportChart'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Article from './Article'
import {KTSVG} from 'src/marketConnect/helpers'
import {getDashboardNewsAsync, getDashboardStatDataAsync, getUserReportsAsync} from 'src/redux/actions/authActions'
import Loader from 'src/utils/Loader'
import useAppToast from 'src/hooks/useAppToast'
import {GRAPH_DATA} from 'src/app/_interfaces/orderDetail.interface'
import {addDays} from 'date-fns'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'


type NEWS = {
  id: number
  type: string
  title: string
  description: string
  created_at: string
  updated_at: string
}

const Dashboard: FC = () => {
  const {mainStoreData, fetchedConnectedPlatformLoading} = useAppSelector(
    (state) => state.fetchConnectedPlatform
  )
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  const [loading, setLoading] = useState(false)
  const [statLoading, setStatLoading] = useState(false)
  const [statData, setStatData] = useState({
    orders_sync_errors: 0,
    synced_sku_count: 0,
    total_orders_synced: 0,
    total_sku_count: 0,
    pending_orders_count:0,
  })
  const [filters, setFilters] = useState({
    from_date: moment(addDays(new Date(), -7)).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    to_date: moment(new Date()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    platform_id: '',
    filter_type: '',
  })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [graphData, setGraphData] = useState<GRAPH_DATA[]>([])
  const toast = useAppToast()
  const [news, setNews] = useState<NEWS[]>([])
  const [newsLoading, setNewsLoading] = useState(false)
  
  // const news = [
  //   {
  //     date: '2 Jan 2024',
  //     title: 'Commercium App: Now Available ',
  //     description: "Commercium App: Now Available on Temu EU - Simplify your European e-commerce operations with Commercium's latest integration: Temu EU. Seamlessly connect and manage your product listings, orders, and inventory.",
  //     link: 'https://example.com/news1',
  //   },
  //   {
  //     date: '23 Dec 2023',
  //     title: 'Newly Launched Loyverse POS',
  //     description:"Newly Launched Loyverse POS connection - Streamline your business operations with Loyverse POS's integration with Commercium, enabling seamless connections to multiple platforms.",
  //     link: 'https://example.com/news2',
  //   },
  //   {
  //     date: '11 Nov 2023',
  //     title: 'Temu Product listing Launched',
  //     description:"Temu Product listing Launched - Start selling your products on Temu and reach a wider audience. With a user-friendly platform and a global customer base, Temu offers a great opportunity to grow your business.",
  //     link: 'https://example.com/news3',
  //   },
   
  // ]

  const [currentPage, setCurrentPage] = useState(1)
  const [nextPageAvailable, setNextPageAvailable] = useState(false)
 
   
  useEffect(() => {
     setNewsLoading(true)
     dispatch(getDashboardNewsAsync({page:currentPage }))
    .then((response)=>{
      console.log('response of news', response.payload )
      if(response.payload){
         setNews(response?.payload?.results)
      }
      if(response.payload?.next){
        setNextPageAvailable(true)
      }else{
        setNextPageAvailable(false)
      }
      setNewsLoading(false)
    })
    .catch(()=>{
       setNewsLoading(false)
     })
  }, [currentPage])
  

  useEffect(() => {
    if (fetchedUserAccountData?.id) {
      setLoading(true)
      dispatch(getUserReportsAsync({user_id: fetchedUserAccountData.id, ...filters}))
        .then((res) => {
          console.log('report data', res.payload)
          if (res.payload?.status_code == 200) {
            if (res.payload?.data?.users_data) {
              // setStatData({
              //   orders_sync_errors: res.payload?.data?.users_data?.orders_sync_errors,
              //   synced_sku_count: res.payload?.data?.users_data?.synced_sku_count,
              //   total_orders_synced: res.payload?.data?.users_data?.total_orders_synced,
              //   total_sku_count: res.payload?.data?.users_data?.total_sku_count,
              //   pending_orders_count: res.payload?.data?.users_data?.pending_orders_count,
              // })
            }

            if (res?.payload?.data?.graph_data) {
              setGraphData(res?.payload?.data?.graph_data)
            }
          }
          if (res.payload?.status == 403) {
            toast.warningToast(res.payload?.data?.detail)
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [filters])

  useEffect(() => {
    if(fetchedUserAccountData?.id){
      setStatLoading(true)
      dispatch(getDashboardStatDataAsync({user_id:fetchedUserAccountData?.id}))
      .then((res)=>{
        if(res.payload?.status_code == 200){
          setStatData({
            orders_sync_errors: res.payload?.data?.orders_sync_errors,
            synced_sku_count: res.payload?.data?.synced_sku_count,
            total_orders_synced: res.payload?.data?.total_orders_synced,
            total_sku_count: res.payload?.data?.total_sku_count,
            pending_orders_count: res.payload?.data?.pending_orders_count,
          })
        }
        setStatLoading(false)
      })
      .catch(() => {
        setStatLoading(false)
      });
    }
  }, [])
  

  // if(loading) return <Loader/>

  return (
    <div>
      <div className='row'>
        <div className='col-md-8 col-12'>
          {/* stat card section  */}
          <div className='row g-2'>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-3 pb-3 border-0 shadow bg-light-primary bg-hover-light-secondary h-100'>
                <div className='d-flex flex-column justify-content-between h-100 '>
                  <div className='d-inline-flex align-items-center justify-content-between'>
                    <div>
                      <button className='btn btn-link m-0 p-0' 
                      onClick={()=>{
                        // navigate('/orders-all')
                      }} 
                      >
                        <p
                          className='fs-3 fw-bold mb-0 text-primary'
                          style={{
                            fontFamily: 'sans-serif',
                            filter: 'drop-shadow(1px 1px 1px #0002)',
                          }}
                        >
                          {statLoading ? (
                            <small className='fs-6'>Loading...</small>
                          ) : (
                            statData?.total_sku_count
                          )}
                        </p>
                      </button>
                      <p className='h6 mb-0 text-primary fw-bold'>Total SKU's </p>
                    </div>
                    <div>
                      <i className='bi bi-ui-checks-grid text-primary fs-2x fw-bold'></i>
                    </div>
                  </div>
                  <div className='d-flex'></div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-3 pb-3 border-0 shadow bg-light-success bg-hover-light-secondary h-100'>
                <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='d-inline-flex align-items-center justify-content-between'>
                    <div>
                      <button className='btn btn-link m-0 p-0'
                        onClick={()=>{
                          // navigate('/orders-all/?orderStatus=SYNCED')
                        }} 
                      >
                        <p
                          className='fs-3 fw-bold mb-0 text-success'
                          style={{fontFamily: 'sans-serif'}}
                        >
                          {statLoading ? (
                            <small className='fs-6'>Loading...</small>
                          ) : (
                            statData?.synced_sku_count
                          )}
                        </p>
                      </button>
                      <p className='h6 mb-0 text-success fw-bold'>Synced SKU's</p>
                    </div>
                    <div>
                      <i className='bi bi-link-45deg text-success fs-2x fw-bold'></i>
                    </div>
                  </div>
                  <div className='d-flex'></div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-3 pb-3 border-0 shadow bg-light-warning bg-hover-light-secondary h-100'>
                <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='d-inline-flex align-items-center justify-content-between'>
                    <div>
                      <button className='btn btn-link m-0 p-0'
                       onClick={()=>{
                        // &from_date=${filters.from_date}&to_date=${filters.to_date}
                        navigate(`/orders-all/?sync_status=PENDING&platform_id=${filters.platform_id}`)
                      }} 
                      >
                        <p
                          className='fs-3 fw-bold mb-0 text-warning'
                          style={{fontFamily: 'sans-serif'}}
                        >
                          {statLoading ? (
                            <small className='fs-6'>Loading...</small>
                          ) : (
                             statData?.pending_orders_count
                          )}
                        </p>
                      </button>
                      <p className='h6 mb-0 text-warning fw-bold'>Pending Orders</p>
                    </div>
                    <div>
                      {/* <i className='bi bi-link-45deg text-warning fs-2x fw-bold'></i> */}
                      <i className='bi bi-exclamation-triangle-fill fs-3 text-warning'></i>
                    </div>
                  </div>
                  <div className='d-flex'></div>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-3 pb-3 border-0 shadow bg-light-danger bg-hover-light-secondary h-100'>
                <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='d-inline-flex align-items-center justify-content-between'>
                    <div>
                      <button className='btn btn-link m-0 p-0'
                        onClick={()=>{
                          navigate(`/orders-all/?sync_status=ERROR&platform_id=${filters.platform_id}`)
                        }} 
                      >
                        <p
                          className='fs-3 fw-bold mb-0 text-danger'
                          style={{
                            fontFamily: 'sans-serif',
                            filter: 'drop-shadow(1px 1px 1px #0002)',
                          }}
                        >
                          {statLoading ? (
                            <small className='fs-6'>Loading...</small>
                          ) : (
                            statData?.orders_sync_errors
                          )}
                        </p>
                      </button>
                      <p className='h6 mb-0 text-danger fw-bold'>Order Sync Errors</p>
                    </div>
                    <div>
                      <i className='bi bi-exclamation-octagon-fill fs-3 text-danger'></i>
                    </div>
                  </div>
                  <div className='d-flex'></div>
                </div>
              </div>
            </div>
          </div>
          {/* stat card section  */}
          <div className='chart-section mt-3'>
            {/* {loading ? (
              <Loader />
            ) : (
            )} */}
            <OrderReportChart
              className='border-0 shadow'
              filters={filters}
              setFilters={setFilters}
              graphData={graphData}
              loading={loading}
            />
          </div>
        </div>
        <div className='col-md-4 col-12'>
          <div className='card h-100 shadow border-0 mb-2'>
            <div
              className='card-header ribbon ribbon-start ribbon-clip bg-white border-0 justify-content-end'
              style={{minHeight: '50px'}}
            >
              <div className='ribbon-label'>
                <p className='fs-5 fw-bold mb-0'>News & Updates</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
              <div className="card-title">
            
              </div>
            </div>
            <div className='card-body p-1 overflow-y-scroll' style={{height:'60vh'}} >
              <ul style={{listStyle: 'none'}} className='py-0 mb-0 ps-2'>
              { newsLoading ? 
                  <div className='d-flex align-items-center justify-content-center mt-4' >
                    <Spinner animation='border' /> 
                  </div>
                : null }
                {news?.map((item, index) => (
                  <li key={'newsitem' + index} className='fs-5 mt-2 '>
                    <Article item={item} />
                  </li>
                ))}
              </ul>
              {/* <nav>
                <ul className='pagination p-2 justify-content-end'>
                  <li className='page-item'>
                    <a className='page-link' href='#' aria-label='Previous'>
                      <span aria-hidden='true'>&laquo;</span>
                    </a>
                  </li>
                  <li className='page-item'>
                    <a className='page-link' href='#' aria-label='Next'>
                      <span aria-hidden='true'>&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav> */}
            </div>
            <div className="card-footer bg-white border-0 p-0 ">
            <nav>
                <ul className='pagination p-2 justify-content-end'>
                  <li className='page-item'>
                    <button disabled={newsLoading} className='page-link' aria-label='Previous' onClick={()=>{
                      if(currentPage > 1){
                        setCurrentPage(currentPage-1)
                      }
                    }} >
                      <span aria-hidden='true' className='fs-1 lh-1 mb-2' >&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button disabled={newsLoading} className='page-link' aria-label='Next' onClick={()=>{
                      if(nextPageAvailable){
                        setCurrentPage(currentPage+1)
                      }
                    }} >
                      <span aria-hidden='true' className='fs-1 lh-1 mb-2' >&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* <div className='card h-50 shadow-sm border-0'>
            <div
              className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip'
              style={{minHeight: '50px'}}
            >
              <div className='ribbon-label'>
                {' '}
                <p className='fs-5 fw-bold mb-0'>Setup Guide</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
            </div>
            <div className='card-body px-2 py-0'>
              <ol>
                {[1, 2, 3, 4].map((item) => {
                  return (
                    <li key={'newsitem' + item} className='fs-6 mb-1'>
                      This is a sample update / news
                    </li>
                  )
                })}
              </ol>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
